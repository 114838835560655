import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { Replay, Check } from "@material-ui/icons";
import { TbArrowsExchange } from "react-icons/tb";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
// import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import toastError from "../../errors/toastError";
// import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";

import ButtonWithSpinner from "../ButtonWithSpinner";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
	// const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	const history = useHistory();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleUpdateTicketStatus = async (e, status, userId) => {
		handleClose();
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	// const handleDeleteTicket = async () => {
	// 	try {
	// 		await api.delete(`/tickets/${ticket.id}`);
	// 	} catch (err) {
	// 		toastError(err);
	// 	}
	// };

	// const handleOpenConfirmationModal = e => {
	// 	setConfirmationOpen(true);
	// 	handleClose();
	// };

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{ticket.status === "closed" && (
					<MenuItem>
						<ButtonWithSpinner
							loading={loading}
							startIcon={<Replay />}
							size="small"
							onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.reopen")}
						</ButtonWithSpinner>
					</MenuItem>
				)}
				{ticket.status === "open" && (
					<>
						<MenuItem>
							<ButtonWithSpinner
								loading={loading}
								startIcon={<Replay />}
								size="small"
								onClick={e => handleUpdateTicketStatus(e, "pending", user?.id)}
							>
								{i18n.t("messagesList.header.buttons.return")}
							</ButtonWithSpinner>
						</MenuItem>
						<MenuItem>
							<ButtonWithSpinner
								loading={loading}
								startIcon={<Check />}
								size="small"
								onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
							>
								{i18n.t("messagesList.header.buttons.resolve")}
							</ButtonWithSpinner>
						</MenuItem>
						<MenuItem onClick={handleOpenTransferModal}>
							<ButtonWithSpinner
								startIcon={<TbArrowsExchange />}
								size="small"
							>
								{i18n.t("ticketOptionsMenu.transfer")}
							</ButtonWithSpinner>
						</MenuItem>
					</>
				)}
				{ticket.status === "pending" && (
					<MenuItem>
						<ButtonWithSpinner
							loading={loading}
							startIcon={<Check />}
							size="small"
							onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.accept")}
						</ButtonWithSpinner>
					</MenuItem>
				)}
				{/* <Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<MenuItem onClick={handleOpenConfirmationModal}>
							{i18n.t("ticketOptionsMenu.delete")}
						</MenuItem>
					)}
				/> */}
			</Menu>
			{/* <ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id
					} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket.contact.name
					}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal> */}
			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				ticketWhatsappId={ticket.whatsappId}
			/>
		</>
	);
};

export default TicketOptionsMenu;
