import { createContext, useEffect, useReducer, useState, useCallback, useContext } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { produce } from 'immer';
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { AuthContext } from "../Auth/AuthContext";

function SchedulesReducer(state, action) {
    if (action.type === "LOAD_SCHEDULES") {
        const { schedules, count, hasMore } = action.payload;

        return produce(state, draft => {
            schedules.forEach((schedule) => {
                const scheduleIndex = draft.schedules.findIndex((s) => s.id === schedule.id);

                if (scheduleIndex !== -1) {
                    draft.schedules[scheduleIndex] = schedule;
                } else {
                    draft.schedules.push(schedule);
                }
            });

            draft.count = count;
            draft.hasMore = hasMore;
        });
    }

    if (action.type === "UPDATE_SCHEDULES") {
        const { schedule } = action.payload;
        const scheduleIndex = state.schedules.findIndex((s) => s.id === schedule.id);

        return produce(state, draft => {
            if (scheduleIndex !== -1) {
                draft.schedules[scheduleIndex] = schedule;
            } else {
                draft.schedules.unshift(schedule);
            }

            draft.count = draft.schedules.length; // Corrigido para usar length de schedules
        });
    }

    if (action.type === "DELETE_SCHEDULE") {
        const { scheduleId } = action.payload;

        const scheduleIndex = state.schedules.findIndex((s) => s.id === scheduleId);

        if (scheduleIndex !== -1) {
            return produce(state, draft => {
                draft.schedules.splice(scheduleIndex, 1);
                draft.count = draft.schedules.length; // Corrigido para usar length de schedules
            });
        }

        return state;
    }

    if (action.type === "RESET") {
        return initialState;
    }

    return state;
}

const initialState = {
    schedules: [],
    count: 0,
    hasMore: false,
};

export const SchedulesContext = createContext({});

export function SchedulesContextProvider({ children }) {
    const [schedulesState, dispatch] = useReducer(SchedulesReducer, initialState);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);

    const fetchSchedules = useCallback(async () => {
        try {
            const { data } = await api.get("/schedules/", {
                params: { search, page },
            });

            dispatch({
                type: "LOAD_SCHEDULES",
                payload: {
                    schedules: data.schedules,
                    count: data.count,
                    hasMore: data.hasMore
                }
            });
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    }, [page, search]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchSchedules();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [page, search, fetchSchedules]);

    useEffect(() => {
        const socket = openSocket();

        socket.on(`schedule_${user.id}`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_SCHEDULES", payload: { schedule: data.schedule } });
            }

            // if (data.action === "delete") {
            //     dispatch({ type: "DELETE_SCHEDULE", payload: { scheduleId: +data.scheduleId } });
            // }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = (search) => {
        setSearch(search);
        setPage(1); // Reset page when performing a new search
    };

    const handleDelete = async (scheduleId) => {
        try {
            await api.delete(`/schedules/${scheduleId}`);
            toast.success("Schedule deleted successfully.");
        } catch (err) {
            toastError(err);
        }

        dispatch({ type: "RESET" });

        await fetchSchedules();
    };

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    return (
        <SchedulesContext.Provider value={{
            schedulesState,
            loading,
            search,
            page,
            fetchSchedules,
            handleSearch,
            handleDelete,
            loadMore
        }}>
            {children}
        </SchedulesContext.Provider>
    );
}
