import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SearchIcon from '@material-ui/icons/Search';
import {
    Menu,
    MenuItem,
    Box,
    Typography,
    IconButton,
    TextField,
    InputAdornment
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useStyles = makeStyles(() => ({
    sendMessageIcons: {
        color: "grey",
    },
    description: {
        color: "rgb(162 162 162)"
    },
}));

const MenuMessageTemplateList = ({ disabled }) => {
    const { ticketId } = useParams();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        const getTemplates = async () => {
            const { data: list } = await api.get("/waba/templates");
            setTemplates(list);
        }

        if (!search) {
            getTemplates();
        } else {
            setTemplates(models => models.filter(model => model.description
                .toLowerCase()
                .includes(search
                    .toLowerCase())
            ))
        }
    }, [search]);

    const handleOpenModelMessageList = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleModelMessageClick = () => {
        setAnchorEl(null);
    };

    const handleSendMessageTemplate = async (modelName) => {
        try {
            await api.post(`/waba/sendtemplate/${ticketId}`, {
                name: modelName
            });
        } catch (err) {
            toastError(err);
        }
    }

    return (
        <>
            <IconButton
                aria-label="model-menu"
                aria-haspopup="true"
                onClick={handleOpenModelMessageList}
                component="span"
                disabled={disabled}
            >
                <SpeakerNotesIcon className={classes.sendMessageIcons} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleModelMessageClick}
            >
                <div style={{ padding: "1rem" }}>
                    <TextField
                        id="input-with-icon-textfield"
                        // label="Pesquise por nome"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Pesquise por nome"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "#909090" }} />
                                </InputAdornment>
                            ),
                        }}
                        style={{ marginBottom: "1rem" }}
                    />
                    {
                        templates.map(model =>
                            <MenuItem key={model.id} onClick={handleModelMessageClick}>
                                <Typography component="div" onClick={() => handleSendMessageTemplate(model.name)}>
                                    <Box fontStyle="normal">
                                        {model.description}
                                    </Box>
                                    <Box fontStyle="oblique" fontSize={10} className={classes.description}>
                                        {model.category}
                                    </Box>
                                </Typography>
                            </MenuItem>)
                    }
                </div>
            </Menu>
        </>
    )
}

export default MenuMessageTemplateList;