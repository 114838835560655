import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from "@material-ui/core/Avatar";
import Box from '@material-ui/core/Box';
import Skeleton from "@material-ui/lab/Skeleton";
import api from "../../services/api";

import {
    Paper,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    businessPaper: {
        padding: theme.spacing(1),
        marginBottom: "1rem"
    },
    gridBusiness: {
        backgroundColor: theme.palette.background.paper,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    itemBusiness: {
        padding: "1rem",
        [theme.breakpoints.down('sm')]: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
        },
    },
    itemBusinessConnected: {
        padding: "1rem",
    },
    labelBusiness: {
        color: "#777777"
    },
    isConnectedBusiness: {
        color: "#4caf50",
        fontWeight: "500"
    },
}));

const ConnectionContent = () => {
    const classes = useStyles();
    const [business, setBusiness] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getBusinessProfile = async () => {
            try {
                const { data } = await api.get("/waba/profile/");
                setBusiness(data);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };
        getBusinessProfile();
    }, []);

    return (
        <Paper className={classes.businessPaper} spacing={1} variant="outlined">
            <Grid container className={classes.gridBusiness}>
                <Grid item md={3} xs={12} className={classes.itemBusiness}>
                    <Box display="flex" flexDirection="row">
                        <Box component="div" mr={2}>
                            {loading ? <Skeleton variant="rect" width={50} height={50} style={{ borderRadius: "4px" }} />
                                : <Avatar src={business.pictureUrl} variant="rounded" className={classes.large} />}
                        </Box>
                        <Box component="span" display="block">
                            <Typography variant="caption" className={classes.labelBusiness}>
                                Nome
                            </Typography>
                            <Typography gutterBottom variant="subtitle1">
                                {loading ? <Skeleton animation="wave" height={25} width={160} /> : business.name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item md={3} xs={12} className={classes.itemBusiness}>
                    <Typography variant="caption" className={classes.labelBusiness}>
                        ID da conta do WhatsApp Business
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        {loading ? <Skeleton animation="wave" height={25} width={160} /> : business.id}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item md={3} xs={12} className={classes.itemBusiness}>
                    <Typography variant="caption" className={classes.labelBusiness}>
                        Número Conectado
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        {loading ? <Skeleton animation="wave" height={25} width={160} /> : business.number}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item md={2} xs={12} className={classes.itemBusinessConnected}>
                    <Typography variant="caption" className={classes.labelBusiness}>
                        Status do Número
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" className={classes.isConnectedBusiness}>
                        {loading ? <Skeleton animation="wave" height={25} width={160} /> : business.status}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ConnectionContent;