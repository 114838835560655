import React, { useState, useEffect, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
    makeStyles,
    Paper,
} from "@material-ui/core";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import LeadItem from "../../components/LeadItem";
import LeadItemSkeleton from "../../components/LeadItemSkeleton";
import ContactDrawer from "../../components/ContactDrawer";
import clsx from "clsx";
import FilterLead from "../../components/FilterLead";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const reducer = (state, action) => {
    if (action.type === "LOAD_LEADS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "0",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const Leads = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [cursor, setCursor] = useState("");
    const [nextCursor, setNextCursor] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentLeadDrawer, setCurrentLeadDrawer] = useState(null);
    const [leads, dispatch] = useReducer(reducer, []);
    const [hasMore, setHasMore] = useState(false);

    const [filterOption, setFilterOption] = useLocalStorage("filterOption", JSON.stringify({}));
    const [searchParam, setSearchParam] = useState({});
    const [products, setProducts] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        setSearchParam(JSON.parse(filterOption));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilterOption(JSON.stringify(searchParam))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setCursor("");
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchLeads = async () => {
                try {
                    const search = {};
                    for (const prop in searchParam) {
                        if (searchParam.hasOwnProperty(prop)) {
                            search[prop] = searchParam[prop].map((item) => item.value);
                        }
                    }

                    const { data } = await api.get("/leads/", {
                        params: { ...search, cursor },
                    });

                    dispatch({ type: "LOAD_LEADS", payload: data.leads });
                    setHasMore(data.hasMore);
                    setNextCursor(data.nextCursor);
                    setProducts(data.products);
                    setTypes(data.types);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchLeads();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, cursor]);

    const handleSaveTicket = async (leadId) => {
        if (!leadId) return;
        try {
            const { data: ticket } = await api.post("/tickets", {
                leadId: leadId,
                userId: user?.id,
                status: "open",
                channel: "whatsapp",
            });
            history.push(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const loadMore = () => {
        setCursor(nextCursor);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleDrawerOpen = (leadId) => {
        setCurrentLeadDrawer(leadId);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <MainHeader>
                <Title>{i18n.t("leads.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <span>
                        {!loading && `${count} resultados`}
                    </span>
                    <FilterLead
                        filters={searchParam}
                        setFilters={setSearchParam}
                        listProducts={products}
                        listTypes={types} />
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Paper
                className={clsx(classes.mainPaper, {
                    [classes.mainWrapperShift]: drawerOpen,
                })}
                variant="outlined"
                onScroll={handleScroll}
            >
                {leads.map((lead) => (
                    <LeadItem
                        key={lead.id}
                        lead={lead}
                        handleSaveTicket={handleSaveTicket}
                        handleDrawerLeadOpen={handleDrawerOpen}
                    />
                ))}
                {loading && <LeadItemSkeleton rows={3} />}
            </Paper>
            <ContactDrawer
                open={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                leadId={currentLeadDrawer}
                loading={loading}
            />
        </MainContainer>
    );
};

export default Leads;
