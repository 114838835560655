import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment"
import { AuthContext } from "../../context/Auth/AuthContext";
import { SchedulesContext } from "../../context/Schedule/ScheduleContext";
import { isArray, capitalize } from "lodash";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const ScheduleSchema = Yup.object().shape({
	body: Yup.string().min(5, "Mensagem muito curta").test({
		name: 'bodyOrTemplateRequired',
		exclusive: true,
		message: 'Preencha a Mensagem ou selecione um Template',
		test: function (value) {
			const { templateId } = this.parent;
			return (value && value.trim().length > 0) || templateId !== undefined;
		}
	}),
	templateId: Yup.number().test({
		name: 'bodyOrTemplateRequired',
		exclusive: true,
		message: 'Preencha a Mensagem ou selecione um Template',
		test: function (value) {
			const { body } = this.parent;
			return (value !== undefined && value !== null) || (body && body.trim().length > 0);
		}
	}),
	ticketId: Yup.number().required("Obrigatório"),
	contactId: Yup.number().required("Obrigatório"),
	sendAt: Yup.string().required("Obrigatório")
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, ticketId }) => {
	const classes = useStyles();
	const history = useHistory();

	const { user } = useContext(AuthContext);
	const { fetchSchedules } = useContext(SchedulesContext);

	const initialSchedule = {
		body: "",
		contactId: "",
		templateId: "",
		ticketId: "",
		sendAt: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
		sentAt: ""
	};

	const initialContact = {
		id: "",
		name: "",
		ticketId: "",
		isBusinessContact: false,
	}

	const initialTemplate = {
		id: "",
		description: "",
	}

	const [schedule, setSchedule] = useState(initialSchedule);
	const [currentContact, setCurrentContact] = useState(initialContact);
	const [contacts, setContacts] = useState([initialContact]);
	const [currentTemplate, setCurrentTemplate] = useState(initialTemplate);
	const [templates, setTemplates] = useState([initialTemplate]);

	useEffect(() => {
		if (contactId && contacts.length) {
			const contact = contacts.find(c => c.id === contactId);
			if (contact) {
				setCurrentContact(contact);
			}
		}

		if (ticketId && contacts.length) {
			const contact = contacts.find(c => c.ticketId === +ticketId);
			if (contact) {
				setCurrentContact(contact);
				setSchedule(prevState => {
					return { ...prevState, contactId: contact.id, ticketId: +ticketId }
				});
			}
		}
	}, [contactId, ticketId, contacts]);

	useEffect(() => {
		try {
			(async () => {
				const { data: ticketList } = await api.get('/tickets');
				let customList = ticketList.tickets.map((t) => ({ id: t.contact.id, name: t.contact.name, ticketId: t.id, isBusinessContact: t.channel === "waba" }));
				if (isArray(customList)) {
					setContacts([initialContact, ...customList]);
				}

				const { data: listTemplates } = await api.get("/waba/templates");
				setTemplates([initialTemplate, ...listTemplates]);

				if (contactId) {
					setSchedule(prevState => {
						return { ...prevState, contactId }
					});
				}

				if (!scheduleId) {
					setCurrentTemplate(initialTemplate);
					return;
				}

				const { data } = await api.get(`/schedules/${scheduleId}`);
				setSchedule(prevState => {
					return { ...prevState, ...data, sendAt: moment(data.sendAt).format('YYYY-MM-DDTHH:mm') };
				});
				setCurrentContact({ ...data.contact, ticketId: data.ticketId, isBusinessContact: data.ticket.channel === "waba" });
				setCurrentTemplate(data.template);
			})()
		} catch (err) {
			toastError(err);
		}
	}, [scheduleId, contactId, open]);

	const handleClose = () => {
		onClose();
	};

	const handleSaveSchedule = async values => {
		const scheduleData = { ...values, userId: user.id };
		try {
			if (scheduleId) {
				await api.put(`/schedules/${scheduleId}`, scheduleData);
			} else {
				await api.post("/schedules", scheduleData);
			}
			toast.success(i18n.t("scheduleModal.success"));

			fetchSchedules();

			setCurrentContact(initialContact);
			setSchedule(initialSchedule);
			setCurrentTemplate(initialTemplate);
			// history.push('/schedules')

		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{schedule.status === 'ERRO' ? 'Erro de Envio' : `Mensagem ${capitalize(schedule.status)}`}
				</DialogTitle>
				<Formik
					initialValues={schedule}
					enableReinitialize={true}
					validationSchema={ScheduleSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveSchedule(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								{!ticketId && (
									<div className={classes.multFieldLine}>
										<FormControl
											variant="outlined"
											fullWidth
										>
											<Autocomplete
												fullWidth
												value={currentContact}
												options={contacts}
												disabled={ticketId}
												onChange={(e, contact) => {
													const contactId = contact ? contact.id : '';
													setSchedule({ ...schedule, contactId, ticketId: contact.ticketId, templateId: '' });
													setCurrentContact(contact ? contact : initialContact);
												}}
												getOptionLabel={(option) => option.name}
												getOptionSelected={(option, value) => {
													return value.id === option.id
												}}
												renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contato" />}
											/>
										</FormControl>
									</div>
								)}
								<br />
								{currentContact.isBusinessContact ? (
									<div className={classes.multFieldLine}>
										<FormControl
											variant="outlined"
											fullWidth
										>
											<Autocomplete
												fullWidth
												value={currentTemplate}
												options={templates}
												onChange={(e, template) => {
													const templateId = template ? template.id : '';
													setSchedule({ ...schedule, templateId });
													setCurrentTemplate(template ? template : initialTemplate);
												}}
												getOptionLabel={(option) => option.description}
												getOptionSelected={(option, value) => {
													return value.id === option.id
												}}
												renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Template" />}
											/>
										</FormControl>
									</div>
								) : (
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											rows={9}
											multiline={true}
											// label={i18n.t("scheduleModal.form.body")}
											label="Escreva aqui sua mensagem..."
											name="body"
											error={touched.body && Boolean(errors.body)}
											helperText={touched.body && errors.body}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</div>
								)}
								<br />
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("scheduleModal.form.sendAt")}
										type="datetime-local"
										name="sendAt"
										InputLabelProps={{
											shrink: true,
										}}
										error={touched.sendAt && Boolean(errors.sendAt)}
										helperText={touched.sendAt && errors.sendAt}
										variant="outlined"
										fullWidth
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("scheduleModal.buttons.cancel")}
								</Button>
								{(schedule.sentAt === null || schedule.sentAt === "") && (
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{scheduleId
											? `${i18n.t("scheduleModal.buttons.okEdit")}`
											: `${i18n.t("scheduleModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								)}
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ScheduleModal;
