import React from "react";
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

export default function LeadItemSkeleton({ rows }) {
    return (
        <>
            {Array.from({ length: rows }, (_, index) => (
                <Grid container key={index} >
                    <Grid item xs={2} md={1}>
                        <Box display="flex" alignItems="center" justifyContent="center" p={1} my={0.5}>
                            <Skeleton variant="rect" width={50} height={50} style={{ borderRadius: "4px" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={8} md={4}>
                        <Box display="flex" flexDirection="column" p={2} my={0.5}>
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" width={110} />
                        </Box>
                    </Grid>
                    <Hidden only={['xs', 'sm']}>
                        <Grid item md={3}>
                            <Box display="flex" flexDirection="column" p={2} my={0.5}>
                                <Skeleton animation="wave" width={120} />
                                <Skeleton animation="wave" width={120} />
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box display="flex" flexDirection="column" p={2} my={0.5}>
                                <Skeleton animation="wave" width={120} />
                                <Skeleton animation="wave" width={120} />
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item xs={2} md={1}>
                        <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
                            <Skeleton variant="circle" width={25} height={25} />
                        </Box>
                    </Grid>
                </Grid>
            ))}
        </>
    );
}