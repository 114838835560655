/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
// import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';

import { i18n } from "../../translate/i18n";

// import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
// import MarkdownWrapper from "../MarkdownWrapper";
import { ItemInfo, Emoji, Caption } from "../ContactItemInfo";
// import { Block } from "@material-ui/icons";

import { format } from "date-fns";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactLeadDetails: {
		display: "flex",
		flexDirection: "column",
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact = null, leadId = null, loading }) => {
	const classes = useStyles();
	const [lead, setLead] = useState(null);
	const [loadLead, setLoadLead] = useState(false);

	const emojiMap = {
		1: '🙋‍♂️',
		2: '💼',
		3: '👨‍👩‍👧‍👦'
	};

	useEffect(() => {
		setLoadLead(true);

		const delayDebounceFn = setTimeout(() => {
			const fetchTicket = async () => {
				try {
					const { data } = await api.get("/leads/" + leadId);
					setLead(data);
					setLoadLead(false);
				} catch (err) {
					setLoadLead(false);
					toastError(err);
				}
			};
			if (leadId) {
				fetchTicket();
			}

		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [leadId]);

	// const [modalOpen, setModalOpen] = useState(false);

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { top: "5%" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton onClick={handleDrawerClose}>
					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center" }}>
					{i18n.t("contactDrawer.header")}
				</Typography>
			</div>

			{loading || loadLead ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					{contact && (
						<Paper square variant="outlined" className={classes.contactHeader}>
							<Avatar
								alt={contact.name}
								src={contact.profilePicUrl}
								className={classes.contactAvatar}
							></Avatar>
							<Typography variant="caption">{leadId}</Typography>
							<Typography>{contact.name}</Typography>
							{/* <Typography>
							<Link href={`tel:${contact.number}`}>{contact.number}</Link>
						</Typography>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setModalOpen(true)}
						>
							{i18n.t("contactDrawer.buttons.edit")}
						</Button> */}
						</Paper>)}

					{lead && (
						<Accordion defaultExpanded style={{ marginTop: 8 }}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header"
							>
								<Typography style={{ fontWeight: 500 }}>{i18n.t("contactDrawer.contactInformation")}</Typography>
							</AccordionSummary>
							<AccordionDetails className={classes.contactLeadDetails}>
								{lead.phone[0] && <ItemInfo>
									<Emoji>
										📞
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										<Link href={`tel:${lead.phone[0].ddd.nu_ddd + lead.phone[0].num_telefone}`}>
											{`+55 (${lead.phone[0].ddd.nu_ddd}) ${lead.phone[0].num_telefone}`}
										</Link>
										<Caption>Telefone</Caption>
									</div>
								</ItemInfo>}

								{lead.address && <ItemInfo>
									<Emoji>
										🏙
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.address.cidade}
										<Caption>Cidade</Caption>
									</div>
								</ItemInfo>}

								{lead.email && <ItemInfo>
									<Emoji>
										📧
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.email}
										<Caption>Email</Caption>
									</div>
								</ItemInfo>}

								{/* area empresarial */}
								{lead.business && <ItemInfo>
									<Emoji>
										📇
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.business.cnpj}
										<Caption>CNPJ</Caption>
									</div>
								</ItemInfo>}

								{lead.business && <ItemInfo>
									<Emoji>
										Ⓜ️
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.business.fantasia}
										<Caption>Nome fantasia</Caption>
									</div>
								</ItemInfo>}

								{lead.business && <ItemInfo>
									<Emoji>
										ℹ️
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.business.nome}
										<Caption>Nome da empresa</Caption>
									</div>
								</ItemInfo>}

								{lead.business && <ItemInfo>
									<Emoji>
										✅
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										Ativa
										<Caption>Situação da empresa</Caption>
									</div>
								</ItemInfo>}

								{lead.business && <ItemInfo>
									<Emoji>
										⌛️
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.business.idade > 1 ? `${lead.business.idade} anos` : `${lead.business.idade} ano`}
										<Caption>Situação da empresa</Caption>
									</div>
								</ItemInfo>}

								{lead.business && <ItemInfo>
									<Emoji>
										🏭
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.business.tipo}
										<Caption>Tipo de empresa</Caption>
									</div>
								</ItemInfo>}

								{lead.business && <ItemInfo>
									<Emoji>
										📊
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.business.porte}
										<Caption>Tipo de regime</Caption>
									</div>
								</ItemInfo>}

								{lead.business && <ItemInfo>
									<Emoji>
										📆
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.business.abertura}
										<Caption>Data de abertura</Caption>
									</div>
								</ItemInfo>}
							</AccordionDetails>
						</Accordion>
					)}

					{lead && (
						<Accordion style={{ marginTop: 8 }}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header">
								<Typography style={{ fontWeight: 500 }}>{i18n.t("contactDrawer.businessInformation")}</Typography>
							</AccordionSummary>
							<AccordionDetails className={classes.contactLeadDetails}>
								{lead?.user && <ItemInfo>
									<Emoji>
										🗣️
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead?.user.nickname}
										<Caption>Corretor</Caption>
									</div>
								</ItemInfo>}

								{lead.type && <ItemInfo>
									<Emoji>
										{emojiMap[lead.id_tipo]}
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.type.descricao}
										<Caption>Tipo</Caption>
									</div>
								</ItemInfo>}

								{lead.vidas && <ItemInfo>
									<Emoji>
										👩‍👧‍👦
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.vidas > 1 ? `${lead.vidas} vidas` : `${lead.vidas} vida`}
										<Caption>Qtde de vidas</Caption>
									</div>
								</ItemInfo>}

								{/* area odonto */}
								{lead.dentalPlan && <ItemInfo>
									<Emoji>
										🩺
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{`${lead.dentalPlan.plan.descricao} (R$ ${lead.dentalPlan.plan.preco} por pessoa/mês)`}
										<Caption>Plano</Caption>
									</div>
								</ItemInfo>}

								{lead.accreditedCity && <ItemInfo>
									<Emoji>
										🏙
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.accreditedCity.city.nome}
										<Caption>Cidade credenciada</Caption>
									</div>
								</ItemInfo>}

								{lead.accredited_network.length > 1 && <ItemInfo>
									<Emoji>
										🏥
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.accredited_network.map(network =>
											<Box key={network.name} display="flex" flexDirection="row">
												<Typography variant="caption">{`${network.name} (${network.amount})`}</Typography>
											</Box>)}
										<Caption>Rede credenciada</Caption>
									</div>
								</ItemInfo>}

								{/* area saude */}
								{lead.health && <ItemInfo>
									<Emoji>
										🎂
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.health.idade > 1 ? `${lead.health.idade} anos` : `${lead.health.idade} ano`}
										<Caption>Idade</Caption>
									</div>
								</ItemInfo>}

								{lead.health && <ItemInfo>
									<Emoji>
										🛌
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.health.acomodacao}
										<Caption>Tipo de acomodação</Caption>
									</div>
								</ItemInfo>}

								{lead.health && <ItemInfo>
									<Emoji>
										🤝
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.health.cooparticipacao}
										<Caption>Coparticipação</Caption>
									</div>
								</ItemInfo>}

								{lead.health && <ItemInfo>
									<Emoji>
										💲
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.health.reembolso}
										<Caption>Reembolso?</Caption>
									</div>
								</ItemInfo>}

								{lead.conditionAccession && <ItemInfo>
									<Emoji>
										👨‍🔧
									</Emoji>
									<div style={{ marginLeft: 14, display: "flex", flexDirection: "column" }}>
										{lead.conditionAccession.condition.descricao}
										<Caption>Elegibilidade Adesão</Caption>
									</div>
								</ItemInfo>}
							</AccordionDetails>
						</Accordion>
					)}

					{lead && (
						<Accordion style={{ marginTop: 8 }}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel2a-content"
								id="panel2a-header">
								<Typography style={{ fontWeight: 500 }}>{i18n.t("contactDrawer.leadMovement")}</Typography>
							</AccordionSummary>
							<AccordionDetails className={classes.contactLeadDetails}>
								{lead.log.map((log) => {
									if (log.type.visivel === 1) {
										return (
											<Box key={log.id} display="flex" flexDirection="row" alignItems="center" p={1} marginBottom={1}>
												<Box width={2 / 3} textAlign="left">
													<Typography variant="caption">{log.descricao || log.type.descricao}</Typography>
												</Box>
												<Box width={1 / 3} textAlign="right">
													<Typography variant="caption">{format(new Date(log.created_at), "dd/MM/yyyy p")}</Typography>
												</Box>
											</Box>
										)
									}
									return null; // Não retorna nada se não atender aos critérios
								})}
							</AccordionDetails>
						</Accordion>
					)}

					{/* <Paper square variant="outlined" className={classes.contactDetails}>
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
						></ContactModal>
						<Typography variant="subtitle1">
							{i18n.t("contactDrawer.extraInfo")}
						</Typography>
						{contact?.extraInfo?.map(info => (
							<Paper
								key={info.id}
								square
								variant="outlined"
								className={classes.contactExtraInfo}
							>
								<InputLabel>{info.name}</InputLabel>
								<Typography component="div" noWrap style={{ paddingTop: 2 }}>
									<MarkdownWrapper>{info.value}</MarkdownWrapper>
								</Typography>
							</Paper>
						))}
					</Paper> */}
				</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;
