import React from "react";

import { Avatar, Box } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	header: {
		display: "flex",
		flex: 1,
		padding: "1rem",
		alignItems: "center",
		gap: "1rem",
		cursor: "pointer"
	},
	labels: {
		display: "inline-grid",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("xs")]: {
			width: "20vh",
		},
	}
}));

const TicketInfo = ({ contact, ticket, onClick }) => {
	const classes = useStyles();

	return (
		<header className={classes.header} onClick={onClick}>
			<Avatar src={contact.profilePicUrl} alt="contact_image" />
			<div className={classes.labels}>
				<Box
					component="span"
					textOverflow="ellipsis"
					overflow="hidden"
					style={{ fontWeight: "bold" }}>
					{`${contact.name} #${ticket.id}`}
				</Box>
				<Box
					component="span"
					textOverflow="ellipsis"
					overflow="hidden">
					{ticket.user &&
						`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`}
				</Box>
			</div>
		</header>
	);
};

export default TicketInfo;
