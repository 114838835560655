import React, { useState, useEffect, useCallback } from "react";
import {
    makeStyles,
    Button,
    Menu,
    Box,
    Chip,
    Divider,
    Badge,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import {
    ArrowForwardIos as ArrowForwardIosIcon,
    ArrowBackIos as ArrowBackIosIcon,
    Clear as ClearIcon
} from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { IoIosFunnel } from "react-icons/io";
import { FaBoxOpen } from "react-icons/fa6";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "0",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    titleFilter: {
        fontSize: "1.5rem"
    },
    headerMenu: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& svg": {
            fontSize: "1rem",
            marginRight: "1rem",
            color: "#8c8c8c",
            cursor: "pointer",
        }
    },
    titleMenu: {
        fontSize: "0.5rem",
        padding: "0.5rem",
        marginLeft: "1rem",
        color: "#8c8c8c",
    },
    item: {
        display: 'flex',
        padding: '.5rem',
        cursor: 'pointer',
        justifyContent: "space-between",
        justifyItems: "center",
        borderRadius: "5px",
        "& svg": {
            marginLeft: "1.5rem",
        },
        "&:active": {
            backgroundColor: "#ebf0ff"
        }
    },
    headerSubMenu: {
        display: "flex",
        alignItems: "center",
        padding: "0.5rem",
        "& span": {
            fontWeight: "700",
            marginLeft: ".5rem",
        }
    },
    iconSubMenu: {
        margin: "0 0.5rem",
        color: "#638eff",
        fontSize: "1rem"
    },
    sectionSubMenu: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
    },
    button: {
        color: "#118af3",
        cursor: "pointer",
        textAlign: "center"
    },
    divider: {
        height: 28,
        margin: 4,
    },
    footer: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            fontSize: "1rem",
            margin: "0 .5rem",
        }
    }
}));


// Componente para seleção de filtro
const FilterSelector = ({ onSelectFilter, classes }) => {
    const filters = [
        { key: "searchId", label: "Id do Lead", form: "field" },
        { key: "searchName", label: "Nome do Cliente", form: "field" },
        { key: "searchProduct", label: "Operadora", form: "search" },
        { key: "searchPhone", label: "Telefone Contém", form: "field" },
        { key: "searchType", label: "Tipo de plano", form: "select" },
    ];

    return (
        <Box display="flex" flexDirection="column" p={2}>
            {filters.map((filter) => (
                <div
                    key={filter.key}
                    className={classes.item}
                    onClick={(e) => onSelectFilter(e, filter)}
                >
                    {filter.label} <ArrowForwardIosIcon style={{ color: "#638eff", fontSize: "1rem" }} />
                </div>
            ))}
        </Box>
    );
};

function FilterLead({ filters, setFilters, listProducts, listTypes }) {

    const classes = useStyles();
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [anchorElSubMenu, setAnchorElSubMenu] = useState(null);
    // const [filters, setFilters] = useState({});
    const [fillSelected, setFillSelected] = useState(null);
    const [items, setItems] = useState(0);
    const [input, setInput] = useState("");

    useEffect(() => {
        let sum = 0;
        // Percorra o objeto
        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                // Some à soma total
                sum += filters[key].length;
            }
        }
        setItems(sum)
    }, [filters]);

    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };
    const handleCloseSubMenu = () => {
        setAnchorElSubMenu(null);
    };

    const handleSaveFilter = useCallback((event) => {
        if (input && fillSelected) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [fillSelected.key]: [
                    ...(prevFilters[fillSelected.key] || []),
                    { title: fillSelected.label, value: input },
                ],
            }));
        }
        setAnchorElSubMenu(null);
        setInput("");
    }, [input, fillSelected, setFilters]);

    const handleFillSelected = (event, fill) => {
        setAnchorElSubMenu(event.currentTarget);
        setFillSelected(fill);
        setInput("");
    };

    const handleDelete = (key, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: filters[key].filter(item => item.value !== value)
        }));
    };

    const handleClear = () => {
        setFilters({});
    };

    let contentFieldFilter;
    if (fillSelected && fillSelected.form === 'select') {
        contentFieldFilter = <FormControl style={{ width: 200 }}>
            <InputLabel id="demo-simple-select-label">Selecione um valor</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={input}
                onChange={(e) => setInput(e.target.value)}
            >
                <MenuItem disabled value="">
                    <em>Selecione o tipo de plano</em>
                </MenuItem>
                {listTypes.map(type => <MenuItem key={type.descricao} value={type.descricao}>{type.descricao}</MenuItem>)}
            </Select>
        </FormControl>;
    } else if (fillSelected && fillSelected.form === 'search') {
        contentFieldFilter = <Autocomplete
            id="search-products"
            options={listProducts}
            getOptionLabel={(option) => option.ds_produto}
            onChange={(e, product) => {
                setInput(product ? product.ds_produto : "");
            }}
            freeSolo
            autoHighlight
            style={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Pesquise um valor" margin="normal" />}
        />;
    } else {
        contentFieldFilter = <TextField
            id="standard-basic"
            label="Insira um valor"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            style={{ width: 200 }}
        />;
    }

    return (
        <>
            <Button aria-controls="filters" aria-haspopup="true" onClick={handleClickMenu}>
                <Badge badgeContent={items} color="primary">
                    <IoIosFunnel className={classes.titleFilter} />
                </Badge>
            </Button>

            <Menu
                id="filters"
                anchorEl={anchorElMenu}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <div className={classes.headerMenu}>
                    <label className={classes.titleMenu}>
                        ADICIONAR FILTROS
                    </label>
                    {!!items && <ClearIcon onClick={handleClear} />}
                </div>
                <FilterSelector onSelectFilter={handleFillSelected} classes={classes} />
                <Divider />
                <Box component="div" overflow="auto" p={2} style={{ width: "250px", whiteSpace: 'nowrap' }}>
                    {items ? (
                        Object.entries(filters).map(([key, items]) => (
                            items.map((item, index) => (
                                <Chip
                                    key={index}
                                    label={`${item.title}: ${item.value}`}
                                    onDelete={() => handleDelete(key, item.value)}
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: "5px" }}
                                />
                            ))
                        ))
                    ) : (
                        <span className={classes.footer}>
                            <FaBoxOpen /> Nenhum filtro por aqui...
                        </span>
                    )}
                </Box>
                <Menu
                    id="subMenu"
                    anchorEl={anchorElSubMenu}
                    keepMounted
                    open={Boolean(anchorElSubMenu)}
                    onClose={handleCloseSubMenu}
                >
                    <header className={classes.headerSubMenu}>
                        <ArrowBackIosIcon onClick={handleCloseSubMenu} className={classes.iconSubMenu} />
                        <span >
                            {anchorElSubMenu && fillSelected.label}
                        </span>
                    </header>
                    <Divider />
                    <section className={classes.sectionSubMenu}>
                        {contentFieldFilter}
                        {input && (
                            <p className={classes.button} onClick={handleSaveFilter}>
                                selecionar "<b>{input}</b>"
                            </p>
                        )}
                    </section>
                </Menu>
            </Menu>
        </>
    )
}

export default FilterLead;