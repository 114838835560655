import React, { useState, useEffect } from "react";

import { i18n } from "../../translate/i18n.js";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';

import api from "../../services/api.js";
import toastError from "../../errors/toastError.js";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        // alignItems: "center",
        marginBottom: 12,

    },
    titles: {
        fontWeight: "500",
        textAlign: "start",
        // marginBottom: "1rem"
    },
    actions: {
        display: "flex",
        justifyContent: "end",
    },
    quoted: {
        color: "#909090"
    },
    weekContent: {
        flex: "1",
        padding: "1rem",
    },
    contentExpanded: {
        flexBasis: "100%"
    },
    itemWeek: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "20% 20% 60%",
        padding: ".5rem 0"
    },
    titleDay: {
        fontWeight: "500",
        textAlign: "start",
        textTransform: "capitalize",
    },
    hourContent: {
        flex: "1",
        display: "grid",
        gridTemplateRows: "repeat(2, minmax(0, 1fr))",
    },
    hours: {
        display: "flex",
        justifyContent: "start",
    },
    titlesHour: {
        fontWeight: "500",
        textAlign: "center",
        marginBottom: "1rem"
    },
}));


const Rule = ({ ruleId, settings, setSettings }) => {
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:600px)');

    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchTeams = async () => {
            try {
                const { data } = await api.get("/user/relatedTeams/");
                setTeams(data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toastError(err);
            }
        };
        fetchTeams();
    }, []);

    useEffect(() => {
        const fetchRule = async () => {
            if (!ruleId) return;
            try {
                const { data } = await api.get(`/setting/ruleIdle/${ruleId}`);

                setSettings({
                    activeRule: data.status,
                    teamId: data.teamId,
                    minutesChats: data.minutesIdleChat,
                    minutesUsers: data.minutesIdleUser,
                    days: {
                        seg: data.monday,
                        ter: data.tuesday,
                        quar: data.wednesday,
                        quin: data.thursday,
                        sex: data.friday,
                        sab: data.saturday,
                        dom: data.sunday,
                    },
                    startWorkHours: {
                        seg: data.startMondayHour,
                        ter: data.startTuesdayHour,
                        quar: data.startWednesdayHour,
                        quin: data.startThursdayHour,
                        sex: data.startFridayHour,
                        sab: data.startSaturdayHour,
                        dom: data.startSundayHour,
                    },
                    endWorkHours: {
                        seg: data.endMondayHour,
                        ter: data.endTuesdayHour,
                        quar: data.endWednesdayHour,
                        quin: data.endThursdayHour,
                        sex: data.endFridayHour,
                        sab: data.endSaturdayHour,
                        dom: data.endSundayHour,
                    },
                });
            } catch (err) {
                toastError(err);
            }
        };

        fetchRule();
    }, [ruleId, setSettings]);

    useEffect(() => {
        // Add any necessary side effects here

        const delayDebounceFn = setTimeout(() => {
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [settings]);

    const handleChange = (e) => {
        const { name, object, day, value, type, checked } = e.target;

        if (name === "activeRule") {
            setSettings(prevState => ({
                ...prevState,
                [name]: checked,
            }));
        } else if (name === "minutesChats") {
            setSettings(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
        else if (name === "minutesUsers") {
            setSettings(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
        else if (name === "teamId") {
            setSettings(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
        else if (type === "checkbox") {
            setSettings(prevState => ({
                ...prevState,
                days: {
                    ...prevState.days,
                    [name]: checked,
                },
            }));
        }
        else {
            setSettings(prevState => ({
                ...prevState,
                [object]: {
                    ...prevState[object],
                    [day]: value
                },
            }));
        }
    };

    const renderDay = (day) => {
        return (
            <div key={day} className={classes.itemWeek}>
                <Typography className={classes.titleDay}>{day}</Typography>
                <FormControlLabel
                    value={day}
                    control={
                        <Switch
                            checked={settings.days[day.toLowerCase()]}
                            onChange={handleChange}
                            name={day}
                            color="primary"
                        />
                    }
                />
                <div className={classes.hours}>
                    <TextField
                        id="time"
                        type="time"
                        label="Inicio"
                        value={settings.startWorkHours[day]}
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    object: "startWorkHours",
                                    day,
                                    name: `startWorkHours.${day}`,
                                    value: e.target.value,
                                },
                            })
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        style={{ marginRight: ".15rem" }}
                    />
                    <TextField
                        id="time"
                        type="time"
                        label="Fim"
                        value={settings.endWorkHours[day]}
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    object: "endWorkHours",
                                    day,
                                    name: `endWorkHours.${day}`,
                                    value: e.target.value,
                                },
                            })
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={6} p={1}>
                        <Typography className={classes.titles}>
                            Regra de transferência de tickets
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={6} p={1} className={classes.actions}>
                        <FormControlLabel
                            value="activeRule"
                            control={
                                <Switch
                                    checked={settings.activeRule}
                                    onChange={handleChange}
                                    name="activeRule"
                                    color="primary"
                                />
                            }
                            label="Ativar Regra"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} p={1}>
                        <p className={classes.quoted}>
                            Ative ou desative a regra transferência de tickets automaticamente para o próximo atendente caso o atendente tenha um contato ocioso.
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={6} p={1}>
                        <Typography className={classes.titles}>
                            Atribua a regra a uma equipe
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={6} p={1} className={classes.actions}>
                        <FormControl variant="outlined" size="small" style={{ width: "120px", marginRight: "2rem" }}>
                            <InputLabel id="demo-simple-select-outlined-label">Equipe</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="teamId"
                                value={settings.teamId}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            object: "teamId",
                                            name: `teamId`,
                                            value: e.target.value,
                                        },
                                    })
                                }
                                label="Equipe"
                            >
                                {/* <MenuItem value="">
                                </MenuItem> */}
                                {teams.map(team => <MenuItem key={team.id} value={team.id}>{team.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12} p={1}>
                        <p className={classes.quoted}>
                            Selecione a equipe à qual estas configurações serão atribuídas.
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={6} p={1}>
                        <Typography className={classes.titles}>
                            Tempo máximo de chats ociosos
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={6} p={1} className={classes.actions}>
                        <TextField
                            type="number"
                            label="Minutos"
                            value={settings.minutesChats}
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                                handleChange({
                                    target: {
                                        object: "minutesChats",
                                        name: `minutesChats`,
                                        value: e.target.value,
                                    },
                                })
                            }
                            inputProps={{
                                step: 5, // 5 min
                                min: 5,
                                max: 60
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "65px", marginRight: "2rem" }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} p={1}>
                        <p className={classes.quoted}>
                            Defina o tempo máximo que um atendente pode permanecer com um contato ocioso. Após esse período, se o contato permanecer pendente, será automaticamente redistribuído para outro atendente
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={6} p={1}>
                        <Typography className={classes.titles}>
                            Tempo de duração para usuários inativos
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={6} p={1} className={classes.actions}>
                        <TextField
                            type="number"
                            label="Minutos"
                            value={settings.minutesUsers}
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                                handleChange({
                                    target: {
                                        object: "minutesUsers",
                                        name: `minutesUsers`,
                                        value: e.target.value,
                                    },
                                })
                            }
                            inputProps={{
                                step: 5, // 5 min
                                min: 5,
                                max: 60
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "65px", marginRight: "2rem" }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} p={1}>
                        <p className={classes.quoted}>
                            Configure de acordo com a dinâmica de trabalho da equipe seleciona, qual é o tempo que um atendente é considerado ativo.
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.paper} style={{ flexWrap: "wrap", gap: "1rem" }}>
                <Typography style={{
                    fontWeight: "500",
                }}>
                    Horário de funcionamento
                </Typography>
                <div className={clsx(classes.weekContent, {
                    [classes.contentExpanded]: matches,
                })}>
                    {Object.keys(settings.days).map(day => renderDay(day))}
                </div>
            </Paper >
        </div >
    );
};

export default Rule;
