import React from "react";
import Typography from "@material-ui/core/Typography";

const ItemInfo = ({ children }) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: ".5rem 0 .5rem 0",
        }}>
            {children}
        </div>
    );
};

const Caption = ({ children }) => {
    return (
        <Typography variant="caption" > {children}</Typography>
    );
};

const Emoji = ({ children }) => {
    return (
        <span role="img" aria-label="emoji" style={{ flexShrink: 0 }}>{children}</span>
    );
};

export { ItemInfo, Emoji, Caption };