import { useContext } from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRowSkeleton from "../../../components/TableRowSkeleton";
import IconButton from "@material-ui/core/IconButton";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import { i18n } from "../../../translate/i18n";

import ConfirmationModal from "../../../components/ConfirmationModal";

import { useState } from "react"

import moment from 'moment';
import { capitalize } from 'lodash';

import { SchedulesContext } from "../../../context/Schedule/ScheduleContext";

export default function ScheduleTable({ handleEditSchedule, ticketId }) {
    const { schedulesState, handleDelete, loading } = useContext(SchedulesContext);

    const {
        schedules
    } = schedulesState;

    const [deletingSchedule, setDeletingSchedule] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const truncate = (str, len) => {
        if (str.length > len) {
            return str.substring(0, len) + '...';
        }
        return str;
    }

    const filteredSchedules = ticketId ?
        schedules.filter(schedule => schedule.ticketId === +ticketId).reverse()
        : schedules;

    return (
        <>
            <ConfirmationModal
                title={
                    deletingSchedule &&
                    `${i18n.t("schedules.confirmationModal.deleteTitle")}`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDelete(deletingSchedule.id)}
            >
                {i18n.t("schedules.confirmationModal.deleteMessage")}
            </ConfirmationModal>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{i18n.t("schedules.table.contact")}</TableCell>
                        <TableCell align="center">{i18n.t("schedules.table.body")}</TableCell>
                        <TableCell align="center">{i18n.t("schedules.table.template")}</TableCell>
                        <TableCell align="center">{i18n.t("schedules.table.sendAt")}</TableCell>
                        <TableCell align="center">{i18n.t("schedules.table.status")}</TableCell>
                        <TableCell align="center">{i18n.t("schedules.table.actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {filteredSchedules.map((schedule) => (
                            <TableRow key={schedule.id}>
                                <TableCell align="center">{schedule.contact.name}</TableCell>
                                <TableCell align="center" title={schedule.body}>{truncate(schedule.body, 25)}</TableCell>
                                <TableCell align="center" title={schedule?.template?.description}>{schedule?.template?.description}</TableCell>
                                <TableCell align="center">{moment(schedule.sendAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                <TableCell align="center">{capitalize(schedule.status)}</TableCell>
                                <TableCell align="center">
                                    {schedule.status === "PENDENTE" && (<>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditSchedule(schedule)}
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingSchedule(schedule);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </>)}
                                </TableCell>
                            </TableRow>
                        ))}
                        {loading && <TableRowSkeleton columns={6} />}
                    </>
                </TableBody>
            </Table>
        </>
    );
}