import React, { useState, useCallback, useEffect, useContext } from "react";
import { toast } from "react-toastify";

import { makeStyles, withStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
} from "@material-ui/core";
import {
	Edit,
	DeleteOutline,
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import Rule from "./Rule";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const theme = createTheme({
	palette: {
		primary: green,
	},
});

const Connections = () => {
	const classes = useStyles();

	const initialState = {
		activeRule: true,
		teamId: '',
		minutesChats: 5,
		minutesUsers: 5,
		days: {
			seg: true,
			ter: true,
			quar: true,
			quin: true,
			sex: true,
			sab: false,
			dom: false,
		},
		startWorkHours: {
			seg: "08:00",
			ter: "08:00",
			quar: "08:00",
			quin: "08:00",
			sex: "08:00",
			sab: "08:00",
			dom: "08:00",
		},
		endWorkHours: {
			seg: "17:30",
			ter: "17:30",
			quar: "17:30",
			quin: "17:30",
			sex: "17:30",
			sab: "17:30",
			dom: "17:30",
		},
	};

	const [settings, setSettings] = useState(initialState);
	const [rules, setRules] = useState([]);
	const [rulesOpen, setRulesOpen] = useState(false);
	const [selectedRule, setSelectedRule] = useState(null);
	const [deletingRule, setDeletingRule] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	useEffect(() => {
		// Add any necessary side effects here
		const delayDebounceFn = setTimeout(async () => {
			await fetchRule();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [rulesOpen]);

	const handleOpenRule = () => {
		setSelectedRule(null);
		setRulesOpen(true);
	};

	const handleEditRule = (rule) => {
		setSelectedRule(rule);
		setRulesOpen(true);
	};

	const handleCloseRule = useCallback(() => {
		setRulesOpen(false);
		setSelectedRule(null);
		setSettings(initialState);
	}, [setRulesOpen, setSelectedRule, setSettings]);

	const saveRule = async () => {
		try {
			if (selectedRule?.id) {
				await api.put(`/setting/ruleIdle/${selectedRule.id}`, {
					teamId: settings.teamId,
					minutesIdleChat: settings.minutesChats,
					minutesIdleUser: settings.minutesUsers,
					monday: settings.days.seg,
					tuesday: settings.days.ter,
					wednesday: settings.days.quar,
					thursday: settings.days.quin,
					friday: settings.days.sex,
					saturday: settings.days.sab,
					sunday: settings.days.dom,
					startMondayHour: settings.startWorkHours.seg,
					startTuesdayHour: settings.startWorkHours.ter,
					startWednesdayHour: settings.startWorkHours.quar,
					startThursdayHour: settings.startWorkHours.quin,
					startFridayHour: settings.startWorkHours.sex,
					startSaturdayHour: settings.startWorkHours.sab,
					startSundayHour: settings.startWorkHours.dom,
					endMondayHour: settings.endWorkHours.seg,
					endTuesdayHour: settings.endWorkHours.ter,
					endWednesdayHour: settings.endWorkHours.quar,
					endThursdayHour: settings.endWorkHours.quin,
					endFridayHour: settings.endWorkHours.sex,
					endSaturdayHour: settings.endWorkHours.sab,
					endSundayHour: settings.endWorkHours.dom,
					status: settings.activeRule
				});
			} else {
				await api.post("/setting/ruleIdle/", {
					teamId: settings.teamId,
					minutesIdleChat: settings.minutesChats,
					minutesIdleUser: settings.minutesUsers,
					monday: settings.days.seg,
					tuesday: settings.days.ter,
					wednesday: settings.days.quar,
					thursday: settings.days.quin,
					friday: settings.days.sex,
					saturday: settings.days.sab,
					sunday: settings.days.dom,
					startMondayHour: settings.startWorkHours.seg,
					startTuesdayHour: settings.startWorkHours.ter,
					startWednesdayHour: settings.startWorkHours.quar,
					startThursdayHour: settings.startWorkHours.quin,
					startFridayHour: settings.startWorkHours.sex,
					startSaturdayHour: settings.startWorkHours.sab,
					startSundayHour: settings.startWorkHours.dom,
					endMondayHour: settings.endWorkHours.seg,
					endTuesdayHour: settings.endWorkHours.ter,
					endWednesdayHour: settings.endWorkHours.quar,
					endThursdayHour: settings.endWorkHours.quin,
					endFridayHour: settings.endWorkHours.sex,
					endSaturdayHour: settings.endWorkHours.sab,
					endSundayHour: settings.endWorkHours.dom,
					status: settings.activeRule
				});
			}
			toast.success("Regra salva com sucesso!");
			handleCloseRule();
		} catch (err) {
			toastError(err);
		}
	}

	const handleDeleteRule = async (ruleId) => {
		try {
			await api.delete(`/setting/ruleIdle/${ruleId}`);
			toast.success("Regra deletada com sucesso!");
			await fetchRule();
		} catch (err) {
			toastError(err);
		}
		setDeletingRule(null);
		setRulesOpen(false);
	};

	const fetchRule = async () => {
		const { data } = await api.get("/setting/ruleIdle/");
		setRules(data);
	}

	return (
		<MainContainer>
			<ConfirmationModal
				title={
					deletingRule &&
					`Tem certeza que deseja deletar a regra da equipe ${deletingRule.team.nome
					}?`
				}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={() => handleDeleteRule(deletingRule.id)}
			>
				Você tem certeza? Essa ação não pode ser revertida.
			</ConfirmationModal>
			<MainHeader>
				<Title>
					Regras de Transferências
				</Title>
				<MainHeaderButtonsWrapper>
					{rulesOpen ? (
						<>
							<Button
								variant="contained"
								onClick={handleCloseRule}>
								Voltar
							</Button>
							<ThemeProvider theme={theme}>
								<Button
									variant="contained"
									color="primary"
									onClick={saveRule}
									className={classes.margin}>
									{selectedRule?.id ? "Editar Regra" : "Salvar Regra"}
								</Button>
							</ThemeProvider>

						</>
					) : <Button
						variant="contained"
						color="primary"
						onClick={handleOpenRule}>
						Adicionar Regra
					</Button>
					}
				</MainHeaderButtonsWrapper>
			</MainHeader>
			{!rulesOpen &&
				<Paper className={classes.mainPaper} variant="outlined">
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="center">
									Regra
								</TableCell>
								<TableCell align="center">
									Status
								</TableCell>
								<TableCell align="center">
									Ações
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rules.map(rule => <TableRow key={rule.id}>
								<TableCell align="center">
									{rule.team.nome}
								</TableCell>
								<TableCell align="center">
									{rule.status ? "Ativo" : "Desativado"}
								</TableCell>
								<TableCell align="center">
									<IconButton
										size="small"
										onClick={() => handleEditRule(rule)}
									>
										<Edit />
									</IconButton>
									<IconButton
										size="small"
										onClick={(e) => {
											setConfirmModalOpen(true);
											setDeletingRule(rule);
										}}
									>
										<DeleteOutline />
									</IconButton>
								</TableCell>
							</TableRow>)}
						</TableBody>
					</Table>
				</Paper>}
			{rulesOpen &&
				<Rule
					ruleId={selectedRule?.id}
					settings={settings}
					setSettings={setSettings}
				/>}
		</MainContainer>
	);
};

export default Connections;
