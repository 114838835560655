import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import ListSubheader from "@material-ui/core/ListSubheader";
// import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SpeakerPhoneIcon from '@material-ui/icons/SpeakerPhone';
// import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
// import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
// import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

import BondsSelect from "../components/BondsSelect";

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import clsx from "clsx";
import { Event } from "@material-ui/icons";

function ListItemLink(props) {
  const { icon, primary, to, className, drawerClose } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className} onClick={drawerClose}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    transition: "transform 0.3s ease-in-out"
  },
  rotate: {
    transform: "rotate(-180deg)"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);
  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  const [openRules, setOpenRules] = useState(false);
  const handleClickRules = () => {
    setOpenRules(!openRules);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div>
      <BondsSelect />

      <ListItemLink
        to="/"
        primary="Dashboard"
        icon={<DashboardOutlinedIcon />}
        drawerClose={drawerClose}
      />

      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
        drawerClose={drawerClose}
      />

      <ListItemLink
        to="/leads"
        primary={i18n.t("mainDrawer.listItems.leads")}
        icon={<BallotOutlinedIcon />}
        drawerClose={drawerClose}
      />

      {/* <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}
      /> */}
      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<Event />}
      />
      <ListItem button onClick={handleClickSettings}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Configurações" />
        <ExpandMore className={clsx(classes.icon, { [classes.rotate]: openSettings })} />
      </ListItem>
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink
            to="/connections"
            primary={i18n.t("mainDrawer.listItems.connections")}
            icon={
              <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                <SpeakerPhoneIcon />
              </Badge>
            }
            className={classes.nested}
            drawerClose={drawerClose}
          />
          <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
              <>
                <ListItemLink
                  to="/transfers"
                  primary={i18n.t("mainDrawer.listItems.transfers")}
                  icon={<SyncAltIcon />}
                  className={classes.nested}
                  drawerClose={drawerClose}
                />
              </>
            )}
          />
          <ListItemLink
            to="/quickAnswers"
            primary={i18n.t("mainDrawer.listItems.quickAnswers")}
            icon={<QuestionAnswerOutlinedIcon />}
            className={classes.nested}
            drawerClose={drawerClose}
          />
        </List>
      </Collapse>

      {/* <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            <ListSubheader inset>
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlinedIcon />}
            />
            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
            />
          </>
        )}
      /> */}
    </div>
  );
};

export default MainListItems;
