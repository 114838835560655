import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import { i18n } from "../../translate/i18n";

import ScheduleTable from "../../pages/Schedules/components/ScheduleTable";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import ScheduleModal from "../ScheduleModal";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const ScheduleTableModal = ({ open, onClose, ticketId }) => {
    const classes = useStyles();

    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

    const handleOpenScheduleModel = () => {
        setSelectedSchedule(null);
        setScheduleModalOpen(true);
    };

    const handleCloseScheduleModal = () => {
        setSelectedSchedule(null);
        setScheduleModalOpen(false);
    };

    const handleEditSchedule = (schedule) => {
        setSelectedSchedule(schedule);
        setScheduleModalOpen(true);
    };

    const handleClose = () => {
        setSelectedSchedule(null);
        onClose();
    };

    return (
        <div className={classes.root}>
            <ScheduleModal
                open={scheduleModalOpen}
                onClose={handleCloseScheduleModal}
                scheduleId={selectedSchedule && selectedSchedule.id}
                ticketId={ticketId}
                aria-labelledby="form-dialog-title"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {i18n.t("schedules.title")}
                </DialogTitle>
                <DialogContent dividers>
                    <ScheduleTable
                        handleEditSchedule={handleEditSchedule}
                        ticketId={ticketId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        // disabled={isSubmitting}
                        variant="outlined"
                    >
                        {i18n.t("scheduleModal.buttons.cancel")}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        // disabled={isSubmitting}
                        variant="contained"
                        className={classes.btnWrapper}
                        onClick={() => handleOpenScheduleModel()}
                    >
                        Criar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ScheduleTableModal;