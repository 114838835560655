import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { isBefore, isAfter, sub, isWithinInterval, isToday } from 'date-fns'

import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    // maxWidth: "50%",
    // flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "80%",
      // flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    // maxWidth: "50%",
    // flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "100%",
      // flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [modelRequired, setModelRequired] = useState(true);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);

          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    // Função que verifica se o tamanho da viewport é considerado desktop
    const checkIfDesktop = () => {
      const isDesktopSize = window.matchMedia('(min-width: 768px)').matches;
      setDrawerOpen(isDesktopSize);
    };
    // Verifica o tamanho da viewport inicialmente
    checkIfDesktop();
    // // Adiciona um ouvinte de redimensionamento para verificar alterações no tamanho da viewport
    // const resizeListener = () => {
    //   checkIfDesktop();
    // };
    // window.addEventListener('resize', resizeListener);
    // // Remove o ouvinte de redimensionamento ao desmontar o componente
    // return () => {
    //   window.removeEventListener('resize', resizeListener);
    // };
  }, []); // A dependência vazia garante que este efeito só é executado uma vez durante a montagem

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted successfully.");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    // desbloqueia a mensagem quando o usuário enviar
    socket.on("appMessage", (data) => {
      if (data.action === "create" && !data.message.fromMe && user.balance > 0) {
        setModelRequired(false);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history, user]);

  useEffect(() => {
    const getLastMessageClient = async () => {
      try {
        const { data } = await api.get("/messages/" + ticketId, {
          params: {
            fromMe: false,
            limit: 1
          },
        });

        if (user.balance <= 0) {
          setModelRequired(true);
          return;
        }

        if (
          //Se o chat estiver bloqueado
          ticket.block) {
          setModelRequired(true);
          return;
        }

        if (
          // Verifica se o canal do ticket é WhatsApp não oficial
          ticket.channel === "whatsapp") {
          setModelRequired(false);
          return;
        }

        // Verifica se não há mensagens do cliente
        if (
          !data.messages.length) {
          setModelRequired(true);
          return;
        }

        // Verifica tem mensagem do cliente mas não há data de expiração da janela
        if (
          data.messages.length && !data.ticket.expiration) {
          setModelRequired(false);
          return;
        }

        const initWindow = sub(new Date(data.ticket.expiration), { days: 1 })
        const endWindow = new Date(data.ticket.expiration);
        const dateMessage = new Date(data.messages[0].createdAt);

        // Verifica se a data da mensagem está antes da janela inicial
        if (isBefore(dateMessage, initWindow)) {
          setModelRequired(true);
          return;
        }

        // Verifica se a data da mensagem e a data de hoje está dentro da janela 
        // ou a data do mensagem após a janela
        if ((isWithinInterval(dateMessage, { start: initWindow, end: endWindow }) &&
          isWithinInterval(new Date(), { start: initWindow, end: endWindow })) ||
          (isAfter(dateMessage, endWindow) && isToday(dateMessage))
        ) {
          setModelRequired(false);
          return;
        }
      } catch (err) {
        toastError(err);
      }
    };
    getLastMessageClient()
  }, [ticketId, history, ticket, user]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {/* <div className={classes.ticketInfo}> */}
          <TicketInfo
            contact={contact}
            ticket={ticket}
            onClick={handleDrawerOpen}
          />
          {/* </div> */}
          {/* <div className={classes.ticketActionButtons}> */}
          <TicketActionButtons ticket={ticket} />
          {/* </div> */}
        </TicketHeader>
        <ReplyMessageProvider>
          <MessagesList
            ticketId={ticketId}
            isGroup={ticket.isGroup}
          ></MessagesList>
          <MessageInput
            ticketStatus={ticket.status}
            ticketBlocked={!!ticket.block}
            modelRequired={modelRequired} />
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        leadId={contact?.lead?.id}
        loading={loading}
      />
    </div>
  );
};

export default Ticket;
