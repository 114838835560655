
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

const useStyles = makeStyles((theme) => ({
    grid: {
        borderBottom: "1px solid #f0f0f0"
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    leadName: {
        whiteSpace: 'nowrap',
        fontSize: "1rem",
        fontWeight: "bold"
    },
    label: {
        fontWeight: "bold"
    },
    corretor: {
        fontSize: "0.70rem",
        textTransform: "uppercase"
    },
    span: {
        fontSize: "0.70rem",
        color: "#777777"
    },
}));

export default function LeadItem({ lead, handleSaveTicket, handleDrawerLeadOpen }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const createdDateFormatted = format(new Date(lead.created_at), "d 'de' LLLL 'às' HH:mm'h'", { locale: ptBR })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid key={lead.id} container className={classes.grid}>
            <Grid item xs={2} md={1} onClick={() => handleDrawerLeadOpen(lead.id)}>
                <Box display="flex" alignItems="center" justifyContent="center" p={1} my={0.5}>
                    <Avatar src={`https://app.leadmark.com.br/themes/now/img/operadoras/${lead.product.id}.png`} variant="rounded" className={classes.large} />
                </Box>
            </Grid>
            <Grid item xs={8} md={4} onClick={() => handleDrawerLeadOpen(lead.id)}>
                <Box display="flex" flexDirection="column" p={2} my={0.5}>
                    <Box component="div" textOverflow="ellipsis" overflow="hidden" className={classes.leadName}>
                        {lead.nome}
                    </Box>
                    <Box component="span" my={0.5} display="block" className={classes.span}>
                        {createdDateFormatted}
                    </Box>
                </Box>
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <Grid item md={3} onClick={() => handleDrawerLeadOpen(lead.id)}>
                    <Box display="flex" flexDirection="column" p={2} my={0.5}>
                        <Box component="span" my={0.5} display="block" className={classes.span}>
                            Corretor
                        </Box>
                        <Box component="span" my={0.5} display="block" className={classes.corretor}>
                            {lead.user.name}
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} onClick={() => handleDrawerLeadOpen(lead.id)}>
                    <Box display="flex" flexDirection="column" p={2} my={0.5}>
                        <Box component="span" my={0.5} display="block" className={classes.span}>
                            Telefone
                        </Box>
                        <Box component="span" my={0.5} display="block" className={classes.corretor}>
                            {`(${lead.phone[0].ddd.nu_ddd}) ${lead.phone[0].num_telefone}`}
                        </Box>
                    </Box>
                </Grid>
            </Hidden>
            <Grid item xs={2} md={1}>
                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleSaveTicket(lead.id)}>
                            <WhatsAppIcon fontSize="small" />
                        </MenuItem>
                    </Menu>
                </Box>
            </Grid>
        </Grid>
    )
}

