import React, { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from '@material-ui/core/Avatar';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import clsx from "clsx";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const BASE_URL = "https://app.leadmark.com.br/uploads/imagens/";

const useStyles = makeStyles((theme) => ({
    item: {
        marginBottom: "2rem",
    },
    icon: {
        transition: "transform 0.3s ease-in-out"
    },
    rotate: {
        transform: "rotate(-180deg)"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export default function BondsSelect() {
    const { user } = useContext(AuthContext);

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [vinculosList, setVinculosList] = useState([]);

    const hasVinculo = user?.vinculos;

    useEffect(() => {
        const { ultima_coligada, vinculos } = user;

        if (ultima_coligada && hasVinculo) {
            const foundVinculo = vinculos.find(v => v.id === ultima_coligada);
            setSelectedUser(foundVinculo);

            const filteredVinculos = [...vinculos.filter(v => v.id !== ultima_coligada), user];
            setVinculosList(filteredVinculos);
        } else if (hasVinculo) {
            setSelectedUser(user);
            setVinculosList(vinculos);
        } else {
            setSelectedUser(user);
        }
    }, [hasVinculo, user]);

    const handleClick = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleChangeVinculoUser = async (vinculo) => {
        const userData = {
            ultima_coligada: vinculo === user.id ? null : vinculo
        }
        try {
            await api.put(`/users/${user.id}`, userData);
        } catch (err) {
            toastError(err);
        }
        window.location.reload(true);
    }

    return (
        <div className={classes.item}>
            <ListItem button={hasVinculo} onClick={handleClick} >
                <ListItemAvatar>
                    <Avatar src={`${BASE_URL}${selectedUser.image}`} />
                </ListItemAvatar>
                <ListItemText primary={selectedUser.nickname} />
                {hasVinculo ? <ExpandMore className={clsx(classes.icon, { [classes.rotate]: open })} /> : null}
            </ListItem>

            {hasVinculo ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {vinculosList.map((v) => (
                        <ListItem onClick={() => handleChangeVinculoUser(v.id)} button key={v.id} className={classes.nested}>
                            <ListItemAvatar>
                                <Avatar src={`${BASE_URL}${v.image}`} className={classes.small} />
                            </ListItemAvatar>
                            <ListItemText primary={v.nickname} />
                        </ListItem>
                    ))}
                </Collapse>
            ) : null}
        </div>
    );
}